@import "./src/style/mixins";

.pp-section-hero {
  .cover {
    //max-height: 288px;
    h1 {
      position: absolute;
      top: 17px;
      left: 16px;
      display: block;
      @include title-h1;
      color: #fff;
    }
  }
  flex-grow: 1;
  background: #002C5E;

  &[dir="rtl"]{
    .cover {
      h1 {
        left: unset;
        right: 16px;
      }
    }
  }
}
@import "./src/style/mixins";

header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 19px 16px;
  .logo {
    height: 22px;
    width: 42px;
  }
  .back {
    height: 22px;
    width: 27px;
    margin-right: 15px;
  }
  & > .lang-selector {
    width: 24px;
    height: 24px;
    margin-left: 15px;
  }
  .steps {
    flex-grow: 1;
    //margin-right: 42px;
  }

  &.transparent {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &.tan {
    background: #E5DCD3;
  }

  &[dir="rtl"]{
    .back {
      margin-right: 0;
      margin-left: 15px;
    }
    &>.lang-selector{
      margin-right: 15px;
      margin-left: unset;
    }
    .steps {
      padding-right: 0;
      padding-left: 15px;
    }
  }
}
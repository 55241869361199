@import "./src/style/mixins";




.wallpaper {
  background: #E5DCD3;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: var(--wallpaperPadding);
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--titleMargin);
    h2 {
      font-size: var(--h2Size);
    }
  }
  .cover {
    margin-bottom: var(--coverMarginBottom);
    img {
      height: 100%;
      object-fit: cover;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      //background: linear-gradient(180deg, #FFFFFF, rgba(#fff, 0));
      //background: linear-gradient(180deg, rgba(#fff, 0.5) 0%, rgba(#fff, 0.02) 100%);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.8) 0.01%, rgba(255, 255, 255, 0.85) 16.63%, rgba(255, 255, 255, 0.33) 49.11%, rgba(255, 255, 255, 0.09) 77.81%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .text {
      z-index: 2;
      position: absolute;
      top: var(--coverTextTop);
      left: var(--coverTextLeft);
      right: var(--coverTextLeft);
      .goal {
        max-width: 100%;
      }
      h2 {
        @include HyundaiMedium(30px, 1, 0.016em, 500, #000);
        display: block;
        max-height: 30%;
        font-size: var(--goalSize);
      }
    }
  }
  .title span, .actions .heading span {
      background: #002C5E;
      color: #fff;
      @include HyundaiMedium(18px, 0.8, normal, 500, #fff);
      font-size: var(--numberSize);
      padding: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--numberBlockSize);
    text-align: center;
      height:  var(--numberBlockSize);
      border-radius: 50%;
      margin-right: calc(var(--coverMarginBottom) / 2);
  }
  .actions {
    //margin-top: 10px;
    h2 {
      @include HyundaiMedium(25px, 1, 0.016em, 500, #000);
      font-size: var(--h3Size);
    }

    .heading {
      span, h2 {
        display: block;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: var(--titleMargin);
    }
    .action-list {
      margin-top: 9px;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        .dot {
          width: var(--dotSize);
          height: var(--dotSize);
          border-radius: 50%;
          background: #000;
          margin-right: 1%;
        }
        font-size: var(--liSize);
        white-space: nowrap;
        &:not(:last-of-type){margin-bottom: 10px;}

      }
    }
  }
  &.preview {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;
    height: calc(var(--vh, 1vh) * 100);
    padding: 9vh 6.25vw  12.5vh;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      h2 {
        font-size: 30px;
      }
      span {
        background: #002C5E;
        color: #fff;
        @include HyundaiMedium(18px, 1, normal, 500, #fff);
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        padding-bottom: 1px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .cover {
      //flex-grow: 1;
      //height: var(--previewCoverHeight);
      margin-bottom: 10px;
      img {
        height: 100%;
        object-fit: cover;
      }
      .text {
        z-index: 2;
        position: absolute;
        top: 18px;
        left: 21px;
        right: 21px;
        h2 {
          font-size: var(--previewGoalSize);
        }
        .goal {
          //max-width: 60%;
        }
      }
    }
    .actions {
      //margin-top: 10px;
      .heading {
        span, h2 {
          display: block;
        }
        display: flex;
        flex-direction: row;
        margin-bottom: 9px;
        align-items: center;
        span {
          background: #002C5E;
          color: #fff;
          @include HyundaiMedium(18px, 1, normal, 500, #fff);
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          padding-bottom: 1px;
          text-align: center;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
      h2 {
        font-size: 25px;
      }
      .action-list {
        margin-top: 9px;
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 17px;
          white-space: nowrap;
          &:not(:last-of-type){margin-bottom: 10px;}

          .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #000;
          }
        }
      }
    }
  }
  &.hidden {
    position: absolute;
    top: -9999px;
    left: -99999px;
    z-index: -9999;
  }

  &[dir="rtl"]{

    background: #E5DCD3;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: var(--wallpaperPadding);
    span, h2, li {
      letter-spacing: normal !important;
    }
    .cover {
      .text {
        top: var(--coverTextTop);
        left: unset;
        right: var(--coverTextLeft);
      }
    }
    .title span, .actions .heading span {
      margin-right: 0;
      margin-left: var(--coverMarginBottom);
    }
    .actions {
      .action-list {
        li {
          .dot {
            margin-right: unset;
            margin-left: 1%;
          }

        }
      }
    }
    &.preview {
      padding: 63px 20px 85px 0;
      .title {
        span {
          margin-right: 0;
          margin-left: 8px;
        }
      }
      .cover {
        .text {

          //left: 21px;
          //right: 21px;
          left: unset;
          top: 18px;
          right: 21px;
        }
      }
      .actions {
        .heading {
          span {
            margin-right: 0;
            margin-left: 8px;
          }
        }
        .action-list {
          margin-top: 9px;
          li {
            .dot {
              margin-right: unset;
              margin-left: 1%;
            }
          }
        }
      }
    }
  }
}

@media (max-aspect-ratio: 18.4 / 10) {
  .wallpaper {
    .preview {
      .title {
        h2 {
          font-size: 25px;
        }
      }
    }
  }
}
@import "../../../style/mixins";

.btns {
  display: flex;
  justify-content: center;
  margin-top: 48px;

  .btn {
    margin: 0 8px;
  }
}

.btn-icon {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  transition: 0.26s;
  display: flex;
  align-items: center;

  .icon {
    width: 48px;
    height: 48px;
    border-radius: 50% 50%;
    border: 1px solid #ffffff;
    transition: 0.26s;

    svg {
      width: 14px;
      height: 20px;
      fill: none;
      @include positionCC;
      left: 53%;
    }
  }

  span {
    @include text-simple;
    color: #ffffff;
    margin-left: 16px;
  }

  &.rtl {
    //flex-direction: row-reverse;

    span {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &:hover {

    .icon {
      background-color: $dark-color;
      border-color: $dark-color;
    }
  }
}

.btn {
  padding: 15px 40px;
  @include text-simple;
  color: #ffffff;
  background-color: $dark-color;
  transition: 0.26s;
  text-align: center;

  &:hover {
    background-color: #003776;
  }

  &.white {
    background-color: #ffffff;
    color: $dark-color;

    &:hover {
      background-color: rgba(#ffffff, 0.8);
    }
  }

  &.transparent {
    background: transparent;
    border: 1px solid #002C5E;
    color: #002C5E;
  }

  &.gray {
    background-color: $gray-color;
    color: $dark-color;

    &:hover {
      background-color: #D8DBE4;
    }
  }

  &.submit {
    cursor: pointer;
  }

  &.small {
    padding: 13px 38px;
  }

}

@include break-point(mobile) {
  .btns {
    flex-direction: column;
    margin-top: 40px;

    .btn {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

@import "./src/style/mixins";

.lang-selector {
  .visible {
    width: 25px;
    height: 25px;
  }
  .hidden {
    width: 50vw;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    opacity: 0;
    transition: opacity 0.05s;
    will-change: opacity;
    top: calc(100% + 18px);
    right: -16px;
    z-index: 5;
    .triangle {
      width: 18px;
      height: 18px;
      background: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      position: absolute;
      z-index: 1;
      top: 0;
      right: 19px;
      transform: rotate(45deg) translateY(-30%) translateX(-30%);
    }
    .list {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      padding: 18px 8px;
      span {
        display: block;
        width: 100%;
        text-align: center;
        @include HyundaiRegular(14px, 18px, 0.02em, normal, #000);
        &:not(:last-of-type){
          margin-bottom: 8px;
        }
        &.active {
          color: #898989;
        }
      }
    }
    &.active {
      opacity: 1;
      transition: opacity 0;
    }
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  &[dir="rtl"]{
    .hidden {
      left: -16px;
      right: unset;
      .triangle {
        left: 19px;
        right: unset;
      }
    }
  }
}
@import "./src/style/mixins";

.pp-section-cropper {
  padding-top: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    display: block;
    margin-bottom: 16px;
    padding: 0 16px;
  }
  span {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    @include HyundaiRegular(16px, 20px, normal, normal, #898989);
    text-align: center;
  }
  .cropper {
    flex-grow: 1;
  }
  .buttons {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: calc((100% - 10px) / 2);
    }
  }
  .reactEasyCrop_Image, .reactEasyCrop_Video {
    max-height: unset !important;
  }
}
@import "./src/style/mixins";

.pp-section-first{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h2 {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include title-h2;
    margin-bottom: 16px;
    margin-top: 47px;
  }
  .detection {
    top: -1px;
    width: 100%;
    background: rgba(#E5DCD3, 0.5);
    padding: 60px 16px 0;
    text-align: center;
    .size {
      span {
        display: block;
        @include HyundaiRegular(17px, 24px,  0.02em, normal, #000);
      }
      text-align: center;
      padding-bottom: 24px;
    }
    &.dimmed {
      filter: grayscale(0.5);
      opacity: .5;
    }
  }
  .selectors {
    &.dimmed {
      filter: grayscale(0.5);
      opacity: .5;
      .content {
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
        }
      }
    }
    padding: 22px 8px;
    width: 100%;
    .content {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .size-select {
      margin: 0 6px;
    }
    & > span {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .check {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    label {

      @include HyundaiRegular(16px, 20px,  0.02em, normal, #898989);
    }
    input {
      width: 16px;
      height: 16px;
      border: 1px solid #898989;
      border-radius: 2px;
      margin-right: 8px;
      transition: 0.2s;
      &:checked {
        font: normal normal normal 12px "FontAwesome";
        //background: #000;
        //border-color: #000;

        &:after {
          content: "\f00c";
          //color: #000;
          @include positionCC;
        }
      }
    }
  }
  .select {
    margin-top: 28px;
    padding: 0 8px;
    width: 100%;
    &:after {
      content: "";
      width: 10px;
      height: 5px;
      position: absolute;
      right: 8px;
      bottom: 19px;
      z-index: 2;
      background: url("../../assets/svg/select-arrow.svg") no-repeat center;
    }
    &.active {
      &:after {
        transform: rotate(180deg);
      }
    }
    &.inactive {
      opacity: 0.4;
    }
    select {
      background: transparent;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      @include HyundaiRegular(14px, 18px, 0.02em, normal, #000);
      padding-bottom: 12px;
    }
  }
  .btn {
    width: calc(100% - 32px);
    margin-bottom: 80px;
  }

  &[dir="rtl"]{
    .select  {
      &:after {
        right: unset;
        left: 8px;
      }
    }
    .check {
      input {
        margin-left: 8px;
        margin-right: 0;
      }
    }

  }
}
@import "./src/style/mixins";

.input-default {
  width: 100%;
  input {
    width: 100%;
    background: transparent;
    border: none;
    padding: 12px;
    padding-top: 0;
    border-bottom: 1px solid #000;
    text-align: center;
    @include HyundaiRegular(16px, 20px, 0.02em, normal, #898989);
    &::placeholder {
      color: #898989;
    }
  }

  &.big {
    input {
      @include HyundaiMedium(25px, 34px, 0.0016em, 500, #000);
      &::placeholder {
        color: #000;
      }
    }
  }

  &.has-error {
    input {
      border-color: #FF4828;
      //&, &::placeholder {color: #FF4828;}
    }
  }
}
@import "../../style/mixins";

.pp-section-form {
  background-color: $dark-color;

  .container {
    padding: 32px 30px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .input-wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      padding-right: 24px;
    }

    &[dir="rtl"] {
      .input select {
        background-position-x: left;
      }

      .input-wrapper {
        padding-right: 0;
        padding-left: 24px;
      }
    }
  }

  .input {
    height: 52px;
    width: 180px;

    input,
    label,
    select,
    input::-webkit-input-placeholder {
      @include text-simple;
      color: #ffffff;
    }

    input,
    select {
      width: 100%;
      height: 100%;
      border-bottom: 1px solid rgba(#ffffff, 0.1);
      background-color: transparent;
      transition: 0.26s border-color;
    }

    select {
      appearance: none;
      background: url("../../assets/svg/select-arrow-white.svg") no-repeat right;
      background-position-y: 24px;

      option {
        color: #000000;
      }
    }

    &.has-error {
      input,
      select {
        border-color: red;
      }
    }

    .error {
      position: absolute;
      top: -20px;
      left: 20px;
      background: #FFF;
      font-size: 12px;
      padding: 3px 5px;
      border: 1px solid #FF4828;

      &:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 100%;
        border: 7px solid transparent;
        border-left: 7px solid #FF4828;
        border-top: 7px solid #FF4828;
      }

      &:after {
        content: "";
        position: absolute;
        left: 11px;
        top: 100%;
        border: 6px solid transparent;
        border-left: 6px solid #fff;
        border-top: 6px solid #fff;
      }
    }
  }

  &[dir="rtl"]{
    .input {
      select {
        background-position: left;
      }
    }
  }

  button {
    cursor: pointer;
  }

  &.Riyadh {
    form {
      .input {
        width: calc(15% - 16px);
      }
    }
  }

  &.Jeddah {
    background-color: #fff;

    form {
      flex-wrap: nowrap;
      align-items: stretch;

      .input-wrapper {
        flex-wrap: wrap;
      }

      .input {
        height: 40px;
        width: calc(25% - 24px / 4);
        margin-bottom: 8px;

        input,
        label,
        select,
        input::-webkit-input-placeholder {
          @include text-small;
          color: #666;
        }

        input,
        select {
          padding: 0 20px;
          border: 1px solid #E4DCD3;
          background-color: transparent;
        }

        select {
          appearance: none;
          background: url("../../assets/svg/select-arrow-gray.png") no-repeat right;
          //background-position-y: 24px;
          background-position-x: calc(100% - 5px);

          option {
            color: #000000;
          }
        }

        &.has-error {
          input,
          select {
            border-color: red;
          }
        }
      }

      .btn {
        white-space: nowrap;
        background: #127EB2;
        color: #fff;
        margin-bottom: 8px;

        &:hover {
          background: #00aad2;
        }
      }

      &[dir="rtl"] {

      }
    }
  }
}


@include break-point(tablet) {
  .pp-section-form {
    &.Jeddah {
      form {
        flex-direction: row;
        align-items: stretch;

        .btn {
          background: #127EB2;
          color: #fff;
          margin: 0 0 8px 0;
        }

        .input {
          height: 40px;
          width: calc(25% - 24px / 4);
        }
      }
    }
  }
}

@include break-point(mobile) {

  .pp-section-form {
    &.Jeddah {
      form {
        flex-direction: column;

        .input-wrapper {
          padding: 0;

          .input {
            height: 40px;
            width: 100%;
          }

          .btn {
            margin: 0;
          }
        }
      }
    }

    &.Riyadh {
      form {
        .input {
          width: 100%;
        }
      }
    }

    form {
      flex-direction: column;

      &[dir="rtl"] {
        .input-wrapper {
          flex-direction: column;
          padding: 0;
        }
      }

      .input-wrapper {
        flex-direction: column;
        padding: 0;
      }


      input,
      label,
      select,
      input::-webkit-input-placeholder {
        @include text-small;
      }

      .input {
        width: 100%;
        margin-bottom: 16px;
      }

      button {
        margin-top: 16px;
      }

    }
  }

}

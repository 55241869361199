@import "./style/mixins";

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background: #E5DCD3;
}

body {

  scroll-behavior: smooth;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #E5DCD3;
  margin: 0;
  font-family: "HyundaiRegular", "Arial", "sans-serif";
  &.fixed {
    overflow: hidden;
    height: 100%;
  }
}

.container {
  width: 100%;
  max-width: 1260px;
  padding: 0 30px;
  margin: 0 auto;

  &.no-padding {
    padding: 0;
  }
}

* {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  img {
    width: 100%;
    height: auto;
  }
  img, svg {
    display: block;
  }
  textarea {
    resize: none;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    img {
      border: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
}

.pp-section {
  padding: 96px 0;
  overflow: hidden;

  &.no-padding {
    padding: 0;
  }

  &.no-top {
    padding-top: 0;
  }
}

.counter {
  background-color: $primary-color;
  padding: 3px 12px;
  border-radius: 14px;
  @include text-small;
  color: #ffffff;
  display: inline-block;
}

main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: #fff;
  &[dir="rtl"] {
    .title-block {

      h1,
      h2 {
        line-height: 86px;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
}

.pp-page-elantra.rtl *{
  //font-family: "HyundaiMedium", "NotoSansArabicMedium" !important;
}

@include break-point(mobile) {

  main {
    &[dir="rtl"] {
      .title-block {

        h1,
        h2 {
          line-height: 1.8;
        }
      }
    }
  }

  .pp-section {
    padding: 64px 0;
  }

  .container {
    padding: 0 16px;

    &.slider-container {
      padding: 0;
    }
  }
}
@media screen and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  body {
    overflow: hidden;
  }
  main {

    background: #E7DED5;
  }
  section {
    display: none !important;
  }
  .orientation-banner {
    display: block;
  }
}
@import "../../../style/mixins";

.file-input {
  height: 80px;
  width: 100%;
  border: 1px dashed #B1B9C2;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.16s;
  input {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    cursor: pointer;
  }
  .fileContainer {

  }
  .react-file-reader {
    height: 100%;
  }
  .react-file-reader-button {
    height: 100%;
  }
  .file-preview {

    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .info {
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 10px 10px 8px;
      //background: rgba(#fff, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image {
        height: 38px;
        width: auto;
        margin-bottom: 6px;
      }
      span {
        //@include text-form;
        @include HyundaiRegular(16px, 20px, 0.02em, 400, #898989);
      }
    }
    .preview {
      width: 100%;
      height: auto;
      position: absolute;
      z-index: 1;
      @include positionCC;
    }
  }
  &.has-error {
    background: rgba(#FF4828, 0.05);
    border-color: #FF4828;
    //.error-message {
    //  opacity: 1;
    //}
  }
}

.has-error > .file-input {
  background: #a50034;
}

@import "./src/style/mixins";

.steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .step {
    background: #fff;
    border: 1px solid #B1B9C2;
    @include HyundaiMedium(15px, 20px, normal, normal, #B1B9C2);
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 50%;
    text-align: center;
    margin: 0 10px;
    &.active {
      border-color: #002C5E;
      color: #002C5E;
    }
    &.visited {
      background: #fff;
      border-color: rgba(#002C5E, 0.5);
      color: rgba(#002C5E, 0.5);
    }
  }
}
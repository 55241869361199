@import "./src/style/mixins";

.size-select {
  .image {
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: auto;
    }
  }

  z-index: 2;
  padding: 21px 21px 17px;
  width: calc((100vw - 42px) / 2);
  height: calc((100vw - 42px) / 2);
  max-height: 140px;
  border: 1px solid #B1B9C2;
  border-radius: 5px;
  background: #fff;
  transition: background-color 0.2s;
  will-change: background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image {
    margin-bottom: 12px;
  }

  span {
    @include text-subtitle;
    color: #000;
  }

  &.active {
    background: #E5DCD3;
    border-color: #E5DCD3;
  }
}
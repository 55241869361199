@import "./src/style/mixins";

.pp-section-info {
  padding: 60px 16px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .image-info-form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .file-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      & > span {
        display: block;
        margin-bottom: 10px;
      }
    }
    .input-group {
      width: 100%;
        margin-bottom: 40px;
      &:last-of-type {
        margin-bottom: 20px;
      }
      .input-default:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }
    .btn {
      width: 100%;
      margin-top: 16px;
    }

    .file-section {
      & > span {
        @include HyundaiRegular(16px, 20px, normal, normal, #000);
      }
      &.success {

      }
    }
    .success-block {
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image {
        width: 36px;
        height: 36px;
        margin-bottom: 6px;
      }

      span {
        @include HyundaiRegular(16px, 20px, normal, normal, #898989);
      }
    }
  }
}
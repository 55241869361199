@import "./src/style/mixins";

.pp-section-preview {
  height: 100vh;
  width: 100vw;
  .btn {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  a {
    z-index: 5;
    @include positionCC;
    .download {width: 72px;
    height: 72px;}
  }
}
@import "./src/style/mixins";

.orientation-banner {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
  background: #E7DED5;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include positionCC;
    width: 100%;
    .logo {
      width: 80px;
      height: 42px;
    }
    span {
      margin: 36px 0;
      display: block;
      @include title-h2;
      text-align: center;
    }
    svg {
      width: 89px;
      height: 89px;
      animation: rotate 2s infinite linear;
      transform-origin: center;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(8deg);
  }
  30% {
    transform: rotate(0);
  }
  100%  {
    transform: rotate(0);
  }
}

